const closeModal = (modal) => {
    return (event) => {
        event.preventDefault();
        modal.classList.remove("modal-open")
    }
}

const showModal = (event) => {
    event.preventDefault();

    const triggeredElement = event.currentTarget;

    const modal = document.querySelector(triggeredElement.dataset.target);

    modal.classList.add("modal-open");

    modal.querySelectorAll("[data-dismiss=modal]").forEach(closeButton => {
        closeButton.addEventListener("click", closeModal(modal));
    })
}

const initializeModal = (event) => {
    document.querySelectorAll("[data-toggle=modal]").forEach(modalLink => {
        modalLink.addEventListener("click", showModal);
    })
}


document.addEventListener("turbolinks:load", initializeModal)