import { default as EmblaCarousel } from "embla-carousel"

const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
  prevBtn.addEventListener('click', embla.scrollPrev, false);
  nextBtn.addEventListener('click', embla.scrollNext, false);
};

const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
  return () => {
    if (embla.canScrollPrev()) prevBtn.removeAttribute('disabled');
    else prevBtn.setAttribute('disabled', 'disabled');

    if (embla.canScrollNext()) nextBtn.removeAttribute('disabled');
    else nextBtn.setAttribute('disabled', 'disabled');
  };
};

const initializeSlideshow = () => {
  const slideshow = document.querySelector(".showcase-slideshow")

  const prevBtn = slideshow.querySelector(".embla__button--prev");
  const nextBtn = slideshow.querySelector(".embla__button--next");

  const options = {
    loop: true,
    draggable: true
  };

  const embla = EmblaCarousel(slideshow, options);

  setupPrevNextBtns(prevBtn, nextBtn, embla);

  const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);


  embla.on("select", disablePrevAndNextBtns);
  embla.on("init", disablePrevAndNextBtns);
};

document.addEventListener("turbolinks:load", initializeSlideshow)