const goBack = (event) => {
    if (window.beenHere) {
        event.preventDefault()
        window.history.back()
    }
}

const initializeBack = () => {

    document.querySelectorAll(".button-close").forEach(buttonLink => {
        buttonLink.addEventListener("click", goBack);
    })
}

document.addEventListener("turbolinks:load", initializeBack)

document.addEventListener("turbolinks:request-start", function(event) {
    window.beenHere = true
})